export function toUserFriendlyString(input: string): string {
    // Remove leading underscores and split into parts by underscores
    const parts = input.replace(/^_+/, '').split(/_+/g);

    // Split CamelCase words and flatten the array
    const words = parts.flatMap(part =>
      part.split(/(?=[A-Z])/g)
    );

    // Capitalize first letter and lowercase the rest of each word
    return words.map(word =>
      word.length > 0
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : ''
    ).join(' ');
  }