import { EndpointCallParams } from '@core/api/endpointCallParams';
import { IApiStore } from '@core/api/apiStore';
import { ScoreDataEntity } from 'Procedures/domain/Device';

export type GetScoreDataParams = {
    procedureVersionId: string;
};

export type ScoreDataParams = {
    entity: ScoreDataEntity;
};

export const performGetScoreData = async (
  api: IApiStore,
  params: EndpointCallParams<GetScoreDataParams>
) => {
  const { payload, ...rest } = params;
  return api.get(`content-app/api/v1/procedures/scoreData`, { ...rest, payload });
};

export const performSaveScoreData = async (
  api: IApiStore,
  params: EndpointCallParams<ScoreDataParams>
) => {
  const { payload, ...rest } = params;
  return api.post('content-app/api/v1/procedures/scoreData', { ...rest, payload: {...payload.entity} });
};