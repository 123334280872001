// components/ScoreDataPage/ScoreDataPage.styles.ts

import { createUseStyles } from 'react-jss';
import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  modal: {
    width: theme.spacing(250),
    maxWidth: 'calc(100vw - 200px)',
    maxHeight: 'calc(100vh - 20px)',
    overflowY: 'auto',
    borderRadius: 8,
  },
  variableType: {
    fontSize: 10,
  },
  divider: {
    color: theme.colors.grey_text,
  },
}));